//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Description",
  components: {},
  props:{
    value:{
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
