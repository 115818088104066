//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FormProSetting",
  computed: {
    setup() {
      return this.$store.state.design.settings || false;
    }
  },
  data(){
    return{}
  },
  methods:{
    validate(){
      return []
    }
  }
}
