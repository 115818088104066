//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProcessTree from '@/page/admin/approval-process/component/process/ProcessTree.vue'
import NodeConfig from '@/page/admin/approval-process/component/common/process/config/NodeConfig'

export default {
  name: "ProcessDesign",
  components: {ProcessTree, NodeConfig},
  data() {
    return {
      scale: 100,
      selected: {},
      showInput: false,
      showConfig: false
    }
  },
  computed:{
    selectedNode(){
      console.log('当前选中的流程组件的内容：', this.$store.state.selectedNode);
      return this.$store.state.selectedNode
    }
  },
  mounted() {

  },
  methods: {
    validate(){
      return this.$refs["process-tree"].validateProcess()
    },
    nodeSelected(node){
      console.log('配置节点', node)
      this.showConfig = true
    }
  },
  watch:{
    /*selectedNode:{
      deep: true,
      handler(node){
        console.log("更新")
        this.$refs["process-tree"].nodeDomUpdate(node)
      }
    }*/
  }
}
