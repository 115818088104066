import { render, staticRenderFns } from "./DescriptionConfig.vue?vue&type=template&id=1e8dc89c&scoped=true&"
import script from "./DescriptionConfig.vue?vue&type=script&lang=js&"
export * from "./DescriptionConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e8dc89c",
  null
  
)

export default component.exports