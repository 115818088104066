//
//
//
//

import Node from './Node'

export default {
  name: "EmptyNode",
  components: {Node},
  data() {
    return {}
  },
  methods: {}
}
