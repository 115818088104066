//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from "vuedraggable";

export default {
  name: "SelectInputConfig",
  components: {draggable},
  props:{
    value:{
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {
      dragOption:{
        animation: 300,
        sort: true
      }
    }
  },
  methods: {}
}
