//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrgPicker from "@/page/admin/approval-process/component/common/OrgPicker";
import OrgItems from "../OrgItems";

export default {
  name: "ApprovalNodeConfig",
  components: {OrgPicker, OrgItems},
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showOrgSelect: false,
      orgPickerSelected: [],
      orgPickerType: 'user',
      approvalTypes: [
        {name: '从员工选', type: 'ASSIGN_USER'},
        // {name: '发起人自选', type: 'SELF_SELECT'},
        // {name: '连续多级主管', type: 'LEADER_TOP'},
        {name: '从角色选', type: 'ROLE'},
          {name: '发起人直属主管', type: 'LEADER'},
          //{name: '主管', type: 'LEADER'},
        // {name: '部门', type: 'DEPT'},
        // {name: '发起人自己', type: 'SELF'},
        // {name: '表单内联系人', type: 'FORM_USER'}
      ]
    }
  },
  computed: {
    nodeProps() {
      console.log('nodeProps:', this.$store.state.selectedNode.props);
      return this.$store.state.selectedNode.props
    },
    select() {
      return this.config.assignedUser || []
    },
    forms() {
      return this.$store.state.design.formItems.filter(f => {
        return f.name === 'UserPicker'
      })
    },
    pickerTitle() {
      switch (this.orgPickerType) {
        case 'user':
          return '请选择成员';
        case 'role':
          return '请选择角色';
        case 'dept':
          return '请选择部门';
        case 'org':
          return '请选择部门或成员';
        default:
          return null;
      }
    },
    maxSelect() {
      switch (this.orgPickerType) {
        case 'user':
          return 8;
        case 'role':
          return 0;
        case 'dept':
          return 0;
        case 'org':
          return 0;
        default:
          return null;
      }
    },
    nodeOptions() {
      let values = []
      const excType = ['ROOT', 'EMPTY', "CONDITION", "CONDITIONS", "CONCURRENT", "CONCURRENTS"]
      this.$store.state.nodeMap.forEach((v) => {
        if (excType.indexOf(v.type) === -1) {
          values.push({id: v.id, name: v.name})
        }
      })
      return values
    },
    showMode() {
      switch (this.nodeProps.assignedType) {
        case "ASSIGN_USER":
          return this.nodeProps.assignedUser.length > 0;
        case "SELF_SELECT":
          return this.nodeProps.selfSelect.multiple;
        case "LEADER_TOP":
          return this.nodeProps.formUser !== '';
        case "FORM_USER":
          return true;
        case "ROLE":
          return true;
        case "DEPT":
          return true;
        default:
          return false;
      }
    }
  },
  methods: {
    selectUser() {
      this.orgPickerSelected = this.select
      this.orgPickerType = 'user'
      this.$nextTick(()=>{
        this.$refs.orgPicker.show()
      })
    },
    selectNoSetUser() {
      this.orgPickerSelected = this.config.nobody.assignedUser
      this.orgPickerType = 'user'
      this.$nextTick(()=>{
        this.$refs.orgPicker.show()
      })
    },
    selectDept() {
      this.orgPickerSelected = this.select
      this.orgPickerType = 'dept'
      this.$nextTick(()=>{
        this.$refs.orgPicker.show()
      })
    },
    selectRole() {
      console.log('role2', this.select);
      this.orgPickerSelected = this.select
      this.orgPickerType = 'role'
      this.$nextTick(()=>{
        this.$refs.orgPicker.show()
      })

    },
    selected(select) {
      console.log(select)
      this.orgPickerSelected.length = 0

      let roleList = [];
      select.forEach(val => {
        this.orgPickerSelected.push(val);
        switch (val.type) {
          case 'role':
            roleList.push(val);
            break
          case 'user':
            break
          case 'dept':
            break
        }
      })

      if (roleList.length > 0) {
        this.nodeProps.role = roleList;
      }
    },
    removeOrgItem(index) {
      this.select.splice(index, 1)
    },
    changeAssignedType(val) {
      // 切换审批类型的时候清空选中情况
      this.orgPickerSelected = [];
      this.nodeProps.assignedUser = [];
      this.nodeProps.role = [];
    },
  }
}
