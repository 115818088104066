//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrgPicker from "@/page/admin/approval-process/component/common/OrgPicker";
import {getFormGroups, updateGroup} from '@/lib/data-service/haolv-default/design';
import iconfont from '@/page/admin/approval-process/assets/iconfont/iconfont.json'

export default {
  name: "FormBaseSetting",
  components: {OrgPicker},
  data() {
    return {
      nowUserSelect: null,
      showIconSelect: false,
      select: [],
      newGroup: '',
      fromGroup: [],
      /*notifyTypes:[
        {type:'APP',name:'应用内通知'},
        {type:'EMAIL',name:'邮件通知'},
        {type:'SMS',name:'短信通知'},
        {type:'WX',name:'微信通知'},
        {type:'DING',name:'钉钉通知'},
      ],*/
      colors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      icons: [
        'el-icon-delete-solid',
        'el-icon-s-tools',
        'el-icon-s-goods',
        'el-icon-warning',
        'el-icon-circle-plus',
        'el-icon-camera-solid',
        'el-icon-s-promotion',
        'el-icon-s-cooperation',
        'el-icon-s-platform',
        'el-icon-s-custom',
        'el-icon-s-data',
        'el-icon-s-check',
        'el-icon-s-claim',
      ],
      rules:{
        formName:[{}],
        groupId: [],
      }
    }
  },
  computed: {
    setup() {
      console.log('d:', this.$store.state.design);
      return this.$store.state.design;
    },
  },
  created() {
    this.loadIconfont()
  },
  mounted(){
    this.getGroups()
  },
  methods: {
      changeSwitch(val) {
          this.setup.deduplication = val ? 1 : 0;
      },
    getRule(msg){
      return [{ required: true, message: msg, trigger: 'blur' }]
    },
    loadIconfont(){
      if (iconfont && iconfont.id){
        iconfont.glyphs.forEach(icon => {
          this.icons.push(`${iconfont.font_family} ${iconfont.css_prefix_text}${icon.font_class}`)
        })
      }
    },
    getGroups(){
      getFormGroups().then(rsp => {
        this.fromGroup = rsp.result;
      }).catch(err => this.$message.error('获取分组异常'))
    },
    /*addGroup() {
      if (this.newGroup.trim() !== '') {
        updateGroup({name: this.newGroup.trim()}, 'post').then(rsp => {
          this.$message.success(rsp.data)
          this.getGroups()
        }).catch(err => this.$message.error(err.response.data))
      }
    },*/
    selected(select) {
      this.$set(this.setup.settings, this.nowUserSelect, select)
      //this.setup[this.nowUserSelect] = select
    },
    selectUser(key) {
      this.select = this.setup.settings[key]
      this.nowUserSelect = key
      this.$refs.orgPicker.show()
    },
    validate(){
      this.$refs.baseSetting.validate()
      let err = []
      if (!this.$isNotEmpty(this.setup.formName)){
        err.push('表单名称未设置')
      }
      if (!this.$isNotEmpty(this.setup.groupId)){
        err.push('表单分组未设置')
      }
      /*if (this.setup.settings.notify.types.length === 0){
        err.push('审批消息通知方式未设置')
      }*/
      return err
    },
      changeGroupId(id) {
        this.$emit('changeGroupId', id);
      }
  }
}
