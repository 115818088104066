import { render, staticRenderFns } from "./TaskNode.vue?vue&type=template&id=a08c4c58&scoped=true&"
import script from "./TaskNode.vue?vue&type=script&lang=js&"
export * from "./TaskNode.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a08c4c58",
  null
  
)

export default component.exports