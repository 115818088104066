//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FormAuthorityConfig",
  components: {},
  data() {
    return {
      tableData: [],
      isIndeterminate: false,
      permSelect: '',
      checkStatus: {
        readOnly: true,
        editable: false,
        hide: false
      }
    }
  },
  created() {
    console.log('111');
    console.log(this.formPerms);
    //备份
    // let oldPermMap = this.formPerms.map((val)=>val['id'])
    let oldPermMap = this.formPerms.toMap('id');
    //重新清空，按顺序加载权限
    this.formPerms.length = 0;
    this.formPermsLoad(oldPermMap, this.formData)
  },
  computed: {
    nowNode(){
      return this.$store.state.selectedNode
    },
    formData() {
      return this.$store.state.design.formItems
    },
    formPerms() {
      console.log('formPerms', this.$store.state.selectedNode.props)
      return this.$store.state.selectedNode.props.formPerms
    }
  },
  methods: {
    allSelect(type) {
      this.permSelect = type
      this.formPerms.forEach(f => f.perm = type)
    },
    formPermsLoad(oldPermMap, forms) {
      forms.forEach(form => {
        if (form.name === 'SpanLayout') {
          this.formPermsLoad(oldPermMap, form.props.items)
        } else {
          //刷新名称
          let old = oldPermMap.get(form.id)
          if (old){
            old.title = form.title
            old.required = form.props.required
            this.formPerms.push(old)
          }else {
            this.formPerms.push({
              id: form.id,
              title: form.title,
              required: form.props.required,
              perm: this.$store.state.selectedNode.type === 'ROOT' ? 'E' : 'R'
            })
          }
        }
      })
    },
    handleCheckAllChange() {

    }
  },
  watch: {
    formPerms: {
      deep: true,
      handler() {
        const set = new Set(this.formPerms.map(f => f.perm))
        this.permSelect = set.size === 1 ? set.values()[0] : ''
      }
    },

  }
}
