//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from "vuedraggable";
import FormRender from '@/page/admin/approval-process/component/common/form/FormRender'
import FormDesignRender from '@/page/admin/approval-process/component/form/FormDesignRender'
import FormComponentConfig from '@/page/admin/approval-process/component/common/form/FormComponentConfig'
import {baseComponents} from '@/page/admin/approval-process/component/common/form/ComponentsConfigExport'

export default {
  name: "FormDesign",
  components: {draggable, FormComponentConfig, FormDesignRender, FormRender},
  data() {
    return {
      formData:{},
      libSelect: 0,
      viewFormVisible: false,
      isStart: false,
      showMobile: true,
      baseComponents,
      select: null,
      drag: false,
    }
  },
  computed: {
    forms() {
      console.log('des', this.$store.state.design);
      return this.$store.state.design.formItems || [];
    },
    selectFormItem: {
      get(){
        return this.$store.state.selectFormItem
      },
      set(val){
        this.$store.state.selectFormItem = val
      },
    },
    nodeMap(){
      return this.$store.state.nodeMap
    }
  },
  methods: {
    copy(node, index) {
      this.form.splice(index + 1, 0, Object.assign({}, node))
    },
    getId() {
      return 'field' + (Math.floor(Math.random() * (99999 - 10000)) + 10000).toString()
          + new Date().getTime().toString().substring(5);
    },
    del(index) {
      this.$confirm('删除组件将会连带删除包含该组件的条件以及相关设置，是否继续?', '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      }).then(() => {
        if (this.forms[index].name === 'SpanLayout'){
          //删除的是分栏则遍历删除分栏内所有子组件
          this.forms[index].props.items.forEach(item => {
            this.removeFormItemAbout(item)
          })
          this.forms[index].props.items.length = 0
        }else {
          this.removeFormItemAbout(this.forms[index])
        }
        this.forms.splice(index, 1)
      })
    },
    async removeFormItemAbout(item){
        console.log('触发删除', item);
      this.nodeMap.forEach(node => {
        //搜寻条件，进行移除
        if (node.type === 'CONDITION'){
          node.props.groups.forEach(group => {
            let i = group.cids.remove(item.id)
            if (i > -1){
              //从子条件移除
              group.conditions.splice(i, 1)
            }
          })
        }
        //搜寻权限，进行移除
        if (node.type === 'ROOT' || node.type === 'APPROVAL' || node.type === 'CC'){
          node.props.formPerms.removeByKey('id', item.id)
          if (node.props.formUser === item.id){
            node.props.formUser = ''
          }
        }
      })
    },
    clone(obj) {
      obj.id = this.getId()
      return JSON.parse(JSON.stringify(obj));
    },
    viewForms(){
      this.viewFormVisible = true
    },
    selectItem(cp){
      console.log('cp:', cp);
      this.selectFormItem = cp
    },
    getSelectedClass(cp){
      return this.selectFormItem && this.selectFormItem.id === cp.id ?
          'border-left: 4px solid #409eff':''
    },
    validateItem(err, titleSet, item){
      if (titleSet.has(item.title) && item.name !== 'SpanLayout'){
        err.push(`表单 ${item.title} 名称重复`)
      }
      titleSet.add(item.title)
      if (item.name === 'SelectInput' || item.name === 'MultipleSelect'){
        if (item.props.options.length === 0){
          err.push(`${item.title} 未设置选项`)
        }
      }else if (item.name === 'TableList'){
        if (item.props.columns.length === 0){
          err.push(`明细表 ${item.title} 内未添加组件`)
        }
      }else if (item.name === 'SpanLayout'){
        if (item.props.items.length === 0){
          err.push('分栏内未添加组件')
        }else {
          item.props.items.forEach(sub => this.validateItem(err, titleSet, sub))
        }
      }
    },
    validate(){
      let err = []
      if (this.forms.length > 0){
        let titleSet = new Set()
        this.forms.forEach(item => {
          //主要校验表格及分栏/选择器/表单名称/是否设置
          this.validateItem(err, titleSet, item)
        })
      }else {
        err.push('表单为空，请添加组件')
      }
      return err
    }
  }
}

// 审批表单设计
/*
[
{"title":"分栏布局","name":"SpanLayout","icon":"el-icon-c-scale-to-original","value":[],"valueType":"Array","props":{"items":[]},"id":"field5153123201090"},
{"title":"单行文本输入","name":"TextInput","icon":"el-icon-edit","value":"","valueType":"String","props":{"required":false,"enablePrint":true},"id":"field9050123202369"},
{"title":"多行文本输入","name":"TextareaInput","icon":"el-icon-more-outline","value":"","valueType":"String","props":{"required":false,"enablePrint":true},"id":"field6660923203637"},
{"title":"数字输入框","name":"NumberInput","icon":"el-icon-edit-outline","value":"","valueType":"Number","props":{"required":false,"enablePrint":true},"id":"field3987123205039"},
{"title":"金额输入框","name":"AmountInput","icon":"iconfont icon-zhufangbutiezhanghu","value":"","valueType":"Number","props":{"required":false,"enablePrint":true,"showChinese":true},"id":"field7375823207151"},
{"title":"单选框","name":"SelectInput","icon":"el-icon-circle-check","value":"","valueType":"String","props":{"required":false,"enablePrint":true,"expanding":false,"options":["选项1","选项2"]},"id":"field2403623209184"},
{"title":"多选框","name":"MultipleSelect","icon":"iconfont icon-duoxuankuang","value":[],"valueType":"Array","props":{"required":false,"enablePrint":true,"expanding":false,"options":["选项1","选项2"]},"id":"field6979623215008"},
{"title":"日期时间点","name":"DateTime","icon":"el-icon-date","value":"","valueType":"Date","props":{"required":false,"enablePrint":true,"format":"yyyy-MM-dd HH:mm"},"id":"field8572423219105"},
{"title":"日期时间区间","name":"DateTimeRange","icon":"iconfont icon-kaoqin","valueType":"DateRange","props":{"required":false,"enablePrint":true,"placeholder":["开始时间","结束时间"],"format":"yyyy-MM-dd HH:mm","showLength":false},"id":"field3568823223610"},
{"title":"人员选择","name":"UserPicker","icon":"el-icon-user","value":[],"valueType":"User","props":{"required":false,"enablePrint":true,"multiple":false},"id":"field1351723229064"},
{"title":"部门选择","name":"DeptPicker","icon":"iconfont icon-map-site","value":[],"valueType":"Dept","props":{"required":false,"enablePrint":true,"multiple":false},"id":"field6461923232345"},
{"title":"说明文字","name":"Description","icon":"el-icon-warning-outline","value":"","valueType":"String","props":{"required":false,"enablePrint":true},"id":"field7102923242582"},
{"title":"明细表","name":"TableList","icon":"el-icon-tickets","value":[],"valueType":"Array","props":{"required":false,"enablePrint":true,"showBorder":true,"rowLayout":true,"showSummary":false,"summaryColumns":[],"maxSize":0,"columns":[]},"id":"field7596223245463"}]
 */
