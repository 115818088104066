//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InsertButton from '@/page/admin/approval-process/component/InsertButton.vue'

export default {
  name: "Node",
  components: {InsertButton},
  props:{
    //是否为根节点
    isRoot: {
      type: Boolean,
      default: false
    },
    //是否显示节点体
    show: {
      type: Boolean,
      default: true
    },
    //节点内容区域文字
    content: {
      type: String,
      default: ""
    },
    title:{
      type: String,
      default: "标题"
    },
    placeholder:{
      type: String,
      default: "请设置"
    },
    //节点体左侧图标
    leftIcon: {
      type: String,
      default: undefined
    },
    //头部图标
    headerIcon:{
      type: String,
      default: ''
    },
    //头部背景色
    headerBgc:{
      type: String,
      default: '#576a95'
    },
    //是否显示错误状态
    showError:{
      type: Boolean,
      default: false
    },
    errorInfo:{
      type: String,
      default: '无信息'
    },
  },
  data() {
    return {}
  },
  methods: {}
}
