//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'OpaPermissions',
  data(){
    return {

    }
  },
  computed: {
    operList() {
      console.log('operList', this.$store.state.selectedNode.props);
      return this.$store.state.selectedNode.props.operList
    }
  },
  created() {
  },
  methods: {
    toEdit(row, index) {
      row.edit = true;
      this.$nextTick(()=>{
        this.$refs['check' + index].focus();
      })
    }
  }
}
