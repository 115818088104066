//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "LayoutHeader",
  props:{
    value: {
      type: String,
      default: 'baseSetup'
    }
  },
  data() {
    return {
      viewCode: false,
    };
  },
  computed: {
    setup() {
      return this.$store.state.design
    }
  },
  created() {
    this.check()
  },
  mounted() {
    console.log(document.body.offsetWidth)
    if (document.body.offsetWidth <= 970) {
      this.$msgbox.alert("本设计器未适配中小屏幕，建议您在PC电脑端浏览器进行操作")
    }
    this.listener()
  },
  methods: {
    publish() {
      this.$emit('publish')
    },
    preview() {
      this.$emit('preview')
      //this.
      // this.viewCode = true;
    },
    valid() {
      if (!this.$isNotEmpty(this.setup.group)) {
        this.$message.warning('请选择分组')
        this.$router.push('/layout/baseSetup')
        return false;
      }
      return true;
    },
    exit() {
      this.$confirm('未发布的内容将不会被保存，是否直接退出 ?', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.go(-1);
        //window.location.reload()
        //this.$store.commit('clearTemplate')
        // this.$router.push('/formsPanel')

      })
    },
    to(path) {
      this.$emit('input', path)
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    listener() {
      window.onunload = this.closeBefore()
      window.onbeforeunload = this.closeBefore()
      //window.on('beforeunload',this.closeBefore())
    },
    closeBefore() {
      //alert("您将要离开本页")
      return false
    },
    check() {
      if (this.$store.state.isEdit === null) {
        //this.$router.push("/workPanel");
      }
    }
  }
}
