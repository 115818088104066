import { render, staticRenderFns } from "./TextareaInputConfig.vue?vue&type=template&id=72227828&scoped=true&"
import script from "./TextareaInputConfig.vue?vue&type=script&lang=js&"
export * from "./TextareaInputConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72227828",
  null
  
)

export default component.exports