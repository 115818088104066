//
//
//
//
//
//
//
//
//
//
//

import OrgPicker from "@/page/admin/approval-process/component/common/OrgPicker";
import OrgItems from "../OrgItems";

export default {
  name: "RootConfig",
  components: {OrgPicker, OrgItems},
  props:{
    config:{
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {
      showOrgSelect: false
    }
  },
  computed:{
    select(){
      return this._config.assignedUser
    },
      _config: {
          get() {
              return this.config;
          },
          set(val) {
              this.$emit("update:config", val);
          }
      }
  },
  methods: {
    selectOrg() {
      this.$refs.orgPicker.show()
    },
      selectOrgRole() {
          this.$refs.orgPicker2.show()
      },
    selected(select) {
      this.select.length = 0
      select.forEach(val => this.select.push(val))
    },
    removeOrgItem(index){
      this.select.splice(index, 1)
    }
  }
}
