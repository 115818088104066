//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "InsertButton",
  components: {},
  data() {
    return {}
  },
  computed:{
    selectedNode(){
      this.$store.state.selectedNode
    }
  },
  methods: {
    addApprovalNode(){
      this.$emit('insertNode', "APPROVAL")
    },
    addTaskNode(){
      this.$emit('insertNode', "TASK")
    },
    addCcNode(){
      this.$emit('insertNode', "CC")
    },
    addDelayNode(){
      this.$emit('insertNode', "DELAY")
    },
    addConditionsNode(){
      this.$emit('insertNode', "CONDITIONS")
    },
    addConcurrentsNode(){
      this.$emit('insertNode', "CONCURRENTS")
    },
    addInclusivesNode(){
      this.$emit('insertNode', "INCLUSIVES")
    },
    addTriggerNode(){
      this.$emit('insertNode', "TRIGGER")
    },
    addSubprocessNode(){
      this.$emit('insertNode', "SUBPROCESS")
    }
  }
}
